import { trackSignUpButtonClicked } from '@/common/analytics';
import { Body, Caption, H2, useSearchParams } from '@cointracker/ui';
import { button } from '@cointracker/ui/src/components/base/Button/Button.css';
import { container, glow, title } from '../Landing.css';
import { usePartnerStore } from '../partnerStore';
import { buildGetStartedUrl, getNextUrl } from '../utils';
import {
  promoHeroImage,
  promoLink,
  promotionalContent,
  promotionalInfo,
  promotionalPresentationContainer,
} from './CoinbasePromotion.css';
import CoinbasePromoSVG from './icons/coinbase-promo.svg?react';

export const CoinbasePromotion = () => {
  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const getStartedUrl = buildGetStartedUrl(nextUrl);

  const trackSignup = () => {
    trackSignUpButtonClicked(
      'Get started for free',
      'coinbase-promotion',
      title,
      title,
    );
  };

  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        Coinbase 🤝 CoinTracker
      </H2>
      <div className={promotionalPresentationContainer}>
        <div className={promoHeroImage}>
          <CoinbasePromoSVG />
        </div>
        <div className={promotionalContent}>
          <H2>20% off all plans</H2>
          <div className={promotionalInfo}>
            <Body size="big">
              New users receive: Free upgrade to premium features: performance
              insights, tax loss harvesting, tax lots{' '}
              <strong>(up to a $140 value)</strong>.{' '}
              <a
                className={promoLink}
                href="https://www.cointracker.io/blog/100-coinbase-coverage"
              >
                Learn more
              </a>
            </Body>
            <Caption size="small">
              New Coinbase users get 20% off until 2025.
            </Caption>
            <a
              className={button({ size: 'regular', variant: 'primary' })}
              href={getStartedUrl}
              onClick={trackSignup}
            >
              Get started for free
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
